@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Pacifico&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

main {
  min-height: 80vh;
}

.section-title {
  font-family: "Pacifico", cursive;
  font-weight: 700;
  font-size: 3em;
  margin-bottom: 0.5em;
  letter-spacing: 0px;
}

.section-styling {
  padding: 1em;
  margin: 1em;
  // background-color: #f7f7f7;
  // border-radius: 1em;
}

.large-s-logo {
  width: 6%;
}

.order-item-lg {
  display: block;
}

.order-item-sm {
  display: none;
}

.accordion-content {
  transition: max-height 0.3s ease-out, padding 0.3s ease;
}

.rating span {
  margin: 0.1rem;
}

.reviews {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .left-rev {
    width: 70%;
    margin-right: 1em;
  }
  .right-rev {
    width: 30%;
    padding-left: 1em;
  }

  .revcard {
    width: 80vh;
    margin: 1em 0.5px;
  }
}

.section {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
}

.sec h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 1.825rem;
  margin: 1.3rem 0;
}

.section-lead {
  max-width: 600px;
  margin: 1rem auto 1.5rem;
}

.service a {
  color: #5b7abb;
  display: block;
}

.service h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #56ceba;
  font-size: 1.3rem;
  margin: 1rem 0 0.6rem;
}

.services-grid {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.service {
  background: #fff;
  margin: 20px;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  -webkit-box-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #e7e7e7;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.service:hover {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
}

.service i {
  font-size: 3.45rem;
  margin: 1rem 0;
}

.service1 i,
.service1 h4,
.service1 .cta {
  color: #42b7ca;
}

.service1:hover {
  border: 2px solid #42b7ca;
}

.service2 i,
.service2 h4,
.service2 .cta {
  color: #425fca;
}

.service2:hover {
  border: 2px solid #425fca;
}

.service3 i,
.service3 h4,
.service3 .cta {
  color: #9c42ca;
}

.service3:hover {
  border: 2px solid #9c42ca;
}

.service .cta span {
  font-size: 0.6rem;
}

.service > * {
  flex: 1 1 100%;
}

.service .cta {
  align-self: flex-end;
}

.product-edit-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .product-preview {
    width: 30%;
  }

  .product-info {
    width: 70%;
  }
}

.yt {
  height: 78vh;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 4px 12px;
}

.wwd-card-container {
  padding: 1.5em;
  color: white;
  background-color: #030c22;
}

.wwd-card {
  background-color: #091a44;
  color: white;
  border-radius: 1.2em;
  margin-right: 0.3em;
  margin-left: 0.3em;
  padding: 2em;
  h3 {
    color: white;
  }
  svg {
    fill: white;
    width: 20%;
  }
  width: clamp(300px, 30%, 400px);
}
.article-top {
  margin-bottom: 1em;
}

.article-styling-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h3 {
    font-size: clamp(3em, 50%, 4em);
    color: white;
    font-weight: bold;
  }
  h4 {
    color: white;
    font-weight: bold;
    margin: 0.2em 0 0.3em 0;
  }
  span {
    font-size: 1.5em;
    color: white;
  }
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h1 {
    font-size: clamp(2.2em, 60%, 4em);
    color: black;
    font-weight: bold;
    margin-top: 0.2em;
  }
  h4 {
    font-weight: bold;
    margin: 0.2em 0 0.3em 0;
    font-size: 2em;
  }
  .stock-button {
    width: fit-content;
    margin-top: 0.25em;
    margin-bottom: 0.4em;
    span {
      padding: 0.15em 1em 0.15em 1em;
      color: black;
    }
  }
}

.main-container {
  .row1 {
    display: flex;
    // Relative positioning for the cake image

    .cake-image {
      position: relative;
      width: 50%;
      svg {
        fill: #f5e2e4;
        position: absolute;
        bottom: -1px;
        right: 0;
        width: 100%;
      }
    }
    .cta1 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2em;
      background-color: #f5f3e7;
      width: 50%;
      h1 {
        font-family: "Pacifico", cursive;
        font-weight: 400;
        font-size: 5em;
      }
    }
  }
  .row2 {
    display: flex;
    .cta2 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #f5e2e4;
      width: 50%;
      padding: 2em;
      h1 {
        font-family: "Pacifico", cursive;
        font-weight: 200;
        font-size: 5em;
      }
    }
    .cups-image {
      width: 50%;
      position: relative;
      svg {
        fill: #f5f3e7;
        position: absolute;
        top: 0;
      }
    }
  }
}

@media (max-width: 900px) {
  .yt {
    height: 70vh;
  }
}

@media (max-width: 768px) {
  .yt {
    height: 50vh;
  }
}

@media (max-width: 600px) {
  .yt {
    height: 40vh;
  }
}

@media (max-width: 480px) {
  .yt {
    height: 30vh;
  }
}

@media all and (max-width: 900px) {
  .services-grid {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.product-list-screen-row {
  padding: 0 1em 10px 1em;
}

.rev-item {
  display: flex;
  align-items: center;
  .card-img-top {
    width: 45%;
  }
  .left-rev-item {
    width: 40%;
  }

  .right-rev-item {
    width: 60%;
  }
}

.search-small {
  display: none;
}
.search-big {
  display: block;
}

// .search-input {
//   width: 100%;
// }

.cat-cards-cont {
  h1 {
    font-size: clamp(2.2em, 60%, 4em);
    font-weight: bold;
    margin-top: 0.2em;
  }
  p {
    font-size: clamp(1.2em, 60%, 2em);
  }
  button {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.cat-cards-1 {
  background-image: url("https://i.ibb.co/rpWbwkw/SBK.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cat-cards-2 {
  background-image: url("https://i.ibb.co/N6M6kLx/SBK-2.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cat-cards-3 {
  background-image: url("https://i.ibb.co/PN8cKKD/SBK-3.png");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
}

.cat-cards-4 {
  background-image: url("https://i.ibb.co/qDKJTqg/SBK-4.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 999px) {
  .cat-cards-cont {
    h1 {
      font-size: clamp(2.2em, 60%, 4em);
      color: white;
      font-weight: bold;
      margin-top: 0.2em;
    }
    p {
      font-size: clamp(1.2em, 60%, 2em);
      font-weight: 400;
    }
    button {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }

  .cat-cards-1 {
    background-image: url("https://i.ibb.co/rpWbwkw/SBK.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #F4F0DD;
  }

  .cat-cards-2 {
    background-image: url("https://i.ibb.co/N6M6kLx/SBK-2.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .cat-cards-3 {
    background-image: url("https://i.ibb.co/PN8cKKD/SBK-3.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .cat-cards-4 {
    background-image: url("https://i.ibb.co/qDKJTqg/SBK-4.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .search-big {
    display: none;
  }

  .search-input {
    margin-top: 1em;
    width: 100%;
  }

  .formsubmit {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
    }
  }

  .search-btn {
    font-size: 1.4em;
    width: 98%;
    margin-top: 0.5em;
  }

  .search-small {
    display: block;
  }

  .order-item-lg {
    display: none;
  }

  .order-item-sm {
    display: block;
  }
  .rev-item {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    .card-img-top {
      width: 15%;
    }
    .left-rev-item {
      width: 100%;
    }

    .right-rev-item {
      width: 100%;
    }
  }

  .reviews {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    .left-rev {
      width: 100%;
      margin-right: 1em;
    }
    .right-rev {
      width: 100%;
      padding-left: 0;
    }

    .revcard {
      width: 100%;
      margin: 1.2em 0;
    }
  }
  #hi {
    display: none;
  }
  #hii {
    display: block;
  }
  #hbuttons {
    width: 100%;
    margin: 0.5em 0;
  }
  #htit {
    font-size: 3em;
  }
  .customersupport {
    flex-direction: column;
  }

  .product-edit-container {
    flex-direction: column-reverse;
    .product-preview {
      width: 100%;
      margin-top: 1em;
    }
    .product-info {
      width: 100%;
    }
  }
}

.carousel-control-prev span {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 12px;
}

.carousel-control-next span {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 12px;
}

@media (min-width: 1005px) {
  #hi {
    display: block;
  }
  #hii {
    display: none;
  }
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-control-prev span {
  padding: 10px;
  margin: 5px;
  animation: 0.25s ease;
}

@media (max-width: 1000px) {
  .offer-cards-container {
    .card1 {
      background-color: #f5e2e4;
      padding: 1em;
      margin-right: 0;
      margin-bottom: 1em;
    }
    .card2 {
      background-color: #f5e2e4;
      padding: 1em;
    }
    .card3 {
      background-color: #f5e2e4;
      padding: 1em;
      margin-left: 0;
      margin-top: 1em;
    }
  }

  .carousel-control-prev span {
    background-color: rgba(0, 0, 0, 0.562);
    padding: 12px;
  }

  .carousel-control-next span {
    background-color: rgba(0, 0, 0, 0.562);
    padding: 12px;
  }
}

.carousel-caption h2 {
  color: rgb(0, 0, 0);
  background: rgba(255, 255, 255, 0.795);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 15px;
}

.carousel-indicators li {
  visibility: hidden;
  display: none;
}

.carousel-indicators {
  visibility: hidden;
  display: none;
}

.carousel a {
  margin: 0 auto;
}

.Product-Card {
  margin-bottom: 1rem;
  width: 100%;
  padding: 1em;
}

.Product-Card img {
  max-height: 30vh;
}

.Product-Card-Body {
  padding-left: 0.625rem /* 10px */;
  padding-right: 0.625rem /* 10px */;
  margin-left: 1.25rem;
}

.login-screen {
  display: flex;
  width: 100%;
  .login-screen-left {
    width: 49%;
    img {
      width: 100%;
    }
  }
  .login-screen-right {
    width: 49%;
    padding: 2em;
  }
}

.register-screen {
  display: flex;
  width: 100%;
  .register-screen-left {
    width: 49%;
    img {
      width: 100%;
    }
  }
  .register-screen-right {
    width: 49%;
    padding: 2em;
  }
}

.termscond {
  h3 {
    font-weight: bold;
    font-size: 1.3em;
    margin-top: 1em;
  }
  p {
    font-weight: 500;
    margin-bottom: 1em;
  }
}

// Alternative Hero Section
.hero-alternative {
  background-color: #f4eff0;
  display: flex;
  background-position: center center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;

  .hero-alternative-intro {
    width: 70%;
    margin-top: 1em;
    text-align: left;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 4em;
      margin-bottom: 0.01em;
    }
    p {
      color: rgb(113, 111, 111);
      font-size: 1.5em;
    }

    .contact-button {
      border: 1px solid #efbab2;
    }
    .contact-button:hover {
      border: 1px solid #e2aba2;
      background-color: #e2aba2;
      color: black;
    }
  }
  .products-gif {
    width: 40%;
    justify-content: flex-end;
    margin-top: auto;
  }
}

.story {
  h1 {
    font-family: "Pacifico", cursive;
    font-weight: 700;
    font-size: 3em;
    letter-spacing: 0px;
  }
}

@media (max-width: 600px) {
  .carousel-caption {
    font-size: 2px;
  }
}

@media (max-width: 1000px) {
  .main-container {
    .row1 {
      display: flex;
      flex-direction: column-reverse;
      .cake-image {
        width: 100%;
        svg {
          fill: #f5f3e7;
          top: -2px;
          rotate: 180deg;
          width: 100%;
        }
      }
      .cta1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2em;
        background-color: #f5f3e7;
        width: 100%;
        h1 {
          font-family: "Pacifico", cursive;
          font-weight: 400;
          font-size: 5em;
        }
      }
    }
    .row2 {
      display: flex;
      flex-direction: column-reverse;
      .cta2 {
        display: flex;

        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #f5e2e4;
        width: 100%;
        padding: 2em;
        h1 {
          font-family: "Pacifico", cursive;
          font-weight: 200;
          font-size: 5em;
        }
      }
      .cups-image {
        width: 100%;
        svg {
          fill: #f5e2e4;
          bottom: -2epx;
          rotate: 180deg;
          top: 60%;
          width: 100%;
        }
      }
    }
  }

  .hero-alternative {
    background-color: #f4eff0;
    flex-direction: column-reverse;
    display: flex;
    background-position: center center;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;

    .hero-alternative-intro {
      width: 100%;
      margin-top: 1em;
      text-align: left;
      padding: 1.5em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      button {
        width: 50%;
      }

      h1 {
        font-size: 4em;
        margin-bottom: 0.01em;
      }
      p {
        color: rgb(113, 111, 111);
        font-size: 1.5em;
      }

      .shop-button {
        width: 45%;
      }

      .contact-button {
        border: 1px solid #efbab2;
        width: 45%;
      }
      .contact-button:hover {
        border: 1px solid #e2aba2;
        background-color: #e2aba2;
        color: black;
      }
    }
    .products-gif {
      width: 100%;
      justify-content: flex-end;
      margin-top: auto;
    }
  }
  .carousel-caption h2 {
    padding: 10px;
    font-size: 15px;
  }
  .Product-Card {
    flex-direction: column;
    width: 90%;
    padding: 1em;
  }

  .Product-Card img {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    margin-bottom: 0.6em;
  }

  .Product-Card-Body {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
  }
  .login-screen-left {
    display: none;
  }
  .login-screen-right {
    width: 100%;
  }
  .login-input {
    width: 100%;
  }
  .login-screen {
    display: flex;
    width: 100%;
    .login-screen-left {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .login-screen-right {
      width: 100%;
      padding: 1em;
    }
  }
  .cta-prof {
    padding: 1em;
  }
  .prf-tit {
    margin-top: 1em;
  }
  .top-products {
    flex-direction: column;
  }
  .top-card {
    margin-top: 1em;
  }
}
